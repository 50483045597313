var render = function () {
  var _vm$player, _vm$player2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "team-invite-player-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_vm.showErrorNotifications || _vm.showSuccessNotifications ? _c('b-row', {
          staticClass: "flex-grow-1"
        }, [_c('b-col', [_c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.showErrorNotifications,
            "variant": _vm.MessageType.ERROR
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _vm.showSuccessNotifications ? _c('dismissible-message-box', {
          staticClass: "p-1",
          attrs: {
            "show": _vm.showSuccessNotifications,
            "variant": _vm.MessageType.SUCCESS
          }
        }, [_vm._v(" Saved successfully! ")]) : _vm._e()], 1)], 1) : _vm._e(), _c('b-row', {
          staticClass: "flex-grow-1 flex-row-reverse"
        }, [_c('b-col', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      }
    }])
  }, [_c('b-row', {
    staticClass: " align-items-center"
  }, [_c('b-col', [_c('p', [_vm._v(" Before accepting invite we suggest checking players profile ")])])], 1), _c('b-row', {
    staticClass: "flex-column align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$player = _vm.player) === null || _vm$player === void 0 ? void 0 : _vm$player.avatar_image,
      "variant": "light-primary",
      "size": "104px"
    }
  }), _c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s((_vm$player2 = _vm.player) === null || _vm$player2 === void 0 ? void 0 : _vm$player2.name) + " ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.openPlayerProfile
    }
  }, [_vm._v(" View player profile ")]), _c('b-row', [_c('div', [_c('spinning-button', {
    staticClass: "mr-2",
    attrs: {
      "is-loading": _vm.isLoadingAccept,
      "type": "submit",
      "variant": "success"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.acceptInvitation($event);
      }
    }
  }, [_vm._v(" Accept ")]), _c('spinning-button', {
    attrs: {
      "is-loading": _vm.isLoadingDecline,
      "type": "submit",
      "variant": "danger"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.declineInvitation($event);
      }
    }
  }, [_vm._v(" Decline ")])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }