<template>
  <b-modal
    id="team-join-modal"
    ref="teamJoinModal"
    :visible="showTeamJoinModal"
    body-class="p-0"
    static
    centered
    @change="(val) => $emit('update:show-team-join-modal', val)"
    @close="handleCancelClick"
  >

    <!-- Modal Footer -->
    <template #modal-footer>
      <b-row v-if="showSuccess || showError">
        <dismissible-message-box
          :variant="MessageType.ERROR"
          class="w-100 p-1"
          :show="showError"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          Something went wrong. Please try again or contact our support
        </dismissible-message-box>

        <dismissible-message-box
          :show="showSuccess"
          :variant="MessageType.SUCCESS"
          class="w-100 p-1"
          @message-box-dismissed="emit('show-team-join-modal', false)"
        >
          <feather-icon
            class="mr-50"
            icon="InfoIcon"
          />
          Your successfully send a request to join team {{ team?.name }}
        </dismissible-message-box>
      </b-row>

      <b-row class="justify-content-between flex-grow-1">
        <spinning-button
          :is-loading="isSubmitting"
          variant="primary"
          class="ml-1"
          @click.native="handleJoinClick"
        >
          Request to join
        </spinning-button>

        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="handleCancelClick"
        >
          Close
        </b-button>
      </b-row>
    </template>

    <!-- Modal: Body -->
    <b-row class="mt-2">
      <b-col class="d-flex flex-column align-items-center">
        <div class="mb-2">
          <h4>
            Join team <b>{{ team?.name }}</b>?
          </h4>
        </div>
        <div>
          <b-avatar
            :src="team?.avatar_image"
            :text="team?.name?.substring(0,2)"
            :variant="`light-primary`"
            class="mb-2"
            size="104px"
          />
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import {
  BAvatar, BButton, BCol, BModal, BRow,
} from 'bootstrap-vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import SpinningButton from '@/components/SpinningButton.vue'
import MessageType from '@/constants/MessageType'
import useApi from '@/api/useApi'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    SpinningButton,
    DismissibleMessageBox,
    BAvatar,
    BCol,
    BRow,
    BModal,
  },
  props: {
    showTeamJoinModal: {
      type: Boolean,
      required: true,
    },
    team: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isSubmitting = ref(false)
    const showSuccess = ref(false)
    const showError = ref(false)
    const { team } = useApi()
    const teamJoinModal = ref(null)

    const handleJoinClick = async () => {
      showSuccess.value = false
      showError.value = false
      isSubmitting.value = true

      const { errors } = await team.joinTeam(props.team.id)

      isSubmitting.value = false

      if (errors && errors.length > 0) {
        showError.value = true

        return
      }

      showSuccess.value = true

      await store.dispatch('user/fetchMe')

      setTimeout(() => {
        teamJoinModal.value.hide()
      }, 3000)
    }

    const handleCancelClick = () => {
      isSubmitting.value = false
      showSuccess.value = false
      showError.value = false

      emit('show-team-join-modal', false)
    }

    return {
      MessageType,
      isSubmitting,
      showError,
      showSuccess,

      // Actions
      handleJoinClick,
      handleCancelClick,
      teamJoinModal,
    }
  },
}
</script>

<style lang="scss">
</style>
