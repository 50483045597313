var render = function () {
  var _vm$team, _vm$team2, _vm$team2$name, _vm$team3, _vm$team4, _vm$team5, _vm$team6, _vm$team7, _vm$team8, _vm$team8$upcoming_to, _vm$team9, _vm$team10, _vm$team11, _vm$team12, _vm$team13, _vm$team13$past_tourn, _vm$team14, _vm$team15, _vm$team15$achievemen, _vm$team16, _vm$team17, _vm$team18, _vm$team18$achievemen, _vm$team19, _vm$team19$history, _vm$team20, _vm$team21, _vm$team22, _vm$team22$members, _vm$team23, _vm$team23$invites, _vm$team24;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('team-members-edit-modal', {
    on: {
      "onTeamMemberEdited": _vm.handleTeamMemberEdited
    }
  }), _vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "9",
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_vm.isLoading ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)], 1) : _vm._e(), _vm.team ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$team = _vm.team) === null || _vm$team === void 0 ? void 0 : _vm$team.avatar_image,
      "text": (_vm$team2 = _vm.team) === null || _vm$team2 === void 0 ? void 0 : (_vm$team2$name = _vm$team2.name) === null || _vm$team2$name === void 0 ? void 0 : _vm$team2$name.substring(0, 2),
      "variant": "light-primary",
      "rounded": "",
      "size": "120px"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-start"
  }, [_c('h4', {
    staticClass: "mb-0 mr-1"
  }, [_vm._v(" " + _vm._s((_vm$team3 = _vm.team) === null || _vm$team3 === void 0 ? void 0 : _vm$team3.name) + " ")]), _c('span', {
    staticClass: "ml-1"
  }, [_vm.canEdit ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleEditClick
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)])], 1), _c('b-row', [_c('b-col', [1 === 2 ? _c('div', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s((_vm$team4 = _vm.team) === null || _vm$team4 === void 0 ? void 0 : _vm$team4.email) + " ")]) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    staticClass: "mb-1 mr-1"
  }, [_c('social-links-list', {
    attrs: {
      "links": (_vm$team5 = _vm.team) === null || _vm$team5 === void 0 ? void 0 : _vm$team5.links
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "flex-column"
  }, [_vm.canJoin ? _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_c('b-button', {
    staticClass: "mb-1  w-100",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleTeamJoinModalShow($event);
      }
    }
  }, [_vm._v(" Join team ")])], 1) : _vm._e(), _vm.pendingApproval ? _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 w-100",
    attrs: {
      "size": "sm",
      "variant": "secondary",
      "disabled": "disabled"
    }
  }, [_vm._v(" Waiting approval ")])], 1) : _vm._e(), _vm.canLeave ? _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_c('b-button', {
    staticClass: "mb-1 w-100",
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleLeaveTeamClick($event);
      }
    }
  }, [_vm._v(" Leave team ")])], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', {
    staticClass: "flex-nowrap mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "4"
    }
  }, [_c('b-badge', {
    staticClass: "p-1 font-medium-2 w-100",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" " + _vm._s(((_vm$team6 = _vm.team) === null || _vm$team6 === void 0 ? void 0 : _vm$team6.balance) || 0) + " € ")])], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "flex-column"
  }, [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_vm.canSendFunds ? _c('b-button', {
    staticClass: "mb-1 font-small-1",
    attrs: {
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Send funds ")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "3"
    }
  }, [_vm.canWithdrawFunds ? _c('b-button', {
    staticClass: "mb-1 font-small-1",
    attrs: {
      "to": {
        name: 'apps-users-edit',
        params: {
          id: (_vm$team7 = _vm.team) === null || _vm$team7 === void 0 ? void 0 : _vm$team7.id
        }
      },
      "disabled": "disabled",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Withdraw funds ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "12"
    }
  }, [_c('div', [_c('div', {
    staticClass: "mb-1 mt-1 mt-lg-0"
  }, [_c('h5', [_vm._v("Upcoming and live tournaments")])]), ((_vm$team8 = _vm.team) === null || _vm$team8 === void 0 ? void 0 : (_vm$team8$upcoming_to = _vm$team8.upcoming_tournaments) === null || _vm$team8$upcoming_to === void 0 ? void 0 : _vm$team8$upcoming_to.length) === 0 ? _c('div', [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("The team's energy levels seem to be down lately")]), _vm._v(" 🛏️ . ")]) : _vm._e(), _vm.team && _vm.team.upcoming_tournaments && _vm.team.upcoming_tournaments.length > 0 ? _c('app-timeline', [_c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "tagname": "li"
    }
  }, _vm._l((_vm$team9 = _vm.team) === null || _vm$team9 === void 0 ? void 0 : _vm$team9.upcoming_tournaments, function (tournament) {
    return _c('app-timeline-item', {
      key: tournament.id,
      staticClass: "ml-1",
      attrs: {
        "title": tournament.name,
        "time": _vm.timeTillTournamentStart(tournament),
        "variant": "info"
      }
    });
  }), 1)], 1) : _vm._e()], 1)])], 1) : _vm._e()], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "p-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Tournaments"
    }
  }, [!((_vm$team10 = _vm.team) !== null && _vm$team10 !== void 0 && _vm$team10.past_tournaments) || ((_vm$team11 = _vm.team) === null || _vm$team11 === void 0 ? void 0 : _vm$team11.past_tournaments.length) === 0 ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" Exciting tournaments are coming soon! Stay tuned for the action. ")]) : _vm._e(), (_vm$team12 = _vm.team) !== null && _vm$team12 !== void 0 && _vm$team12.past_tournaments && ((_vm$team13 = _vm.team) === null || _vm$team13 === void 0 ? void 0 : (_vm$team13$past_tourn = _vm$team13.past_tournaments) === null || _vm$team13$past_tourn === void 0 ? void 0 : _vm$team13$past_tourn.length) > 0 ? _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    staticStyle: {
      "max-height": "200px"
    }
  }, [_c('ul', {
    staticClass: "d-flex flex-column align-items-start list-unstyled"
  }, _vm._l((_vm$team14 = _vm.team) === null || _vm$team14 === void 0 ? void 0 : _vm$team14.past_tournaments, function (tournament, index) {
    var _tournament$disciplin;
    return _c('li', {
      key: index,
      staticClass: "m-1"
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'tournament-info',
          params: {
            id: tournament.id,
            game: tournament === null || tournament === void 0 ? void 0 : (_tournament$disciplin = tournament.discipline) === null || _tournament$disciplin === void 0 ? void 0 : _tournament$disciplin.slug
          }
        }
      }
    }, [_c('span', {
      class: "bullet bullet-sm mr-1 bullet-".concat(_vm.tournamentStatusColor(tournament.status_id)),
      attrs: {
        "title": _vm.tournamentStatusTitle(tournament.status_id)
      }
    }), _vm._v(" "), _c('span', {
      attrs: {
        "title": _vm.tournamentStatusTitle(tournament.status_id)
      }
    }, [_vm._v(_vm._s(tournament.name))])])], 1);
  }), 0)]) : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "title": "Achievements"
    }
  }, [((_vm$team15 = _vm.team) === null || _vm$team15 === void 0 ? void 0 : (_vm$team15$achievemen = _vm$team15.achievements) === null || _vm$team15$achievemen === void 0 ? void 0 : _vm$team15$achievemen.length) > 0 ? _c('ul', {
    staticClass: "d-flex m-0 p-0 align-items-center flex-wrap p-2"
  }, _vm._l((_vm$team16 = _vm.team) === null || _vm$team16 === void 0 ? void 0 : _vm$team16.achievements, function (achievement, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(achievement.name) + " ")]);
  }), 0) : _vm._e(), !((_vm$team17 = _vm.team) !== null && _vm$team17 !== void 0 && _vm$team17.achievements) || ((_vm$team18 = _vm.team) === null || _vm$team18 === void 0 ? void 0 : (_vm$team18$achievemen = _vm$team18.achievements) === null || _vm$team18$achievemen === void 0 ? void 0 : _vm$team18$achievemen.length) === 0 ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" Get ready to fill up this space with trophies and triumphs! ")]) : _vm._e()]), _c('b-tab', {
    attrs: {
      "title": "History"
    }
  }, [((_vm$team19 = _vm.team) === null || _vm$team19 === void 0 ? void 0 : (_vm$team19$history = _vm$team19.history) === null || _vm$team19$history === void 0 ? void 0 : _vm$team19$history.length) > 0 ? _c('ul', {
    staticClass: "d-flex m-0 p-0 align-items-center flex-wrap p-2"
  }, _vm._l((_vm$team20 = _vm.team) === null || _vm$team20 === void 0 ? void 0 : _vm$team20.history, function (history, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(history.name) + " ")]);
  }), 0) : _vm._e(), !((_vm$team21 = _vm.team) !== null && _vm$team21 !== void 0 && _vm$team21.history) ? _c('p', {
    staticClass: "font-italic p-2"
  }, [_vm._v(" No history yet. ")]) : _vm._e()])], 1)], 1)], 1)], 1)], 1), _c('b-col', [_vm.isMyTeam && ((_vm$team22 = _vm.team) === null || _vm$team22 === void 0 ? void 0 : (_vm$team22$members = _vm$team22.members) === null || _vm$team22$members === void 0 ? void 0 : _vm$team22$members.length) > 0 ? _c('b-row', [_c('b-col', [_c('team-trusted-members-roles-card', {
    attrs: {
      "members": _vm.trustedMembers,
      "can-edit-members": _vm.canEditMembers
    },
    on: {
      "editTeamMembersClick": _vm.handleEditTeamMembersClick
    }
  })], 1)], 1) : _vm._e(), _vm.canEdit && ((_vm$team23 = _vm.team) === null || _vm$team23 === void 0 ? void 0 : (_vm$team23$invites = _vm$team23.invites) === null || _vm$team23$invites === void 0 ? void 0 : _vm$team23$invites.length) > 0 ? _c('b-row', [_c('b-col', [_c('team-join-request-card', {
    attrs: {
      "team": _vm.team
    }
  })], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', _vm._l((_vm$team24 = _vm.team) === null || _vm$team24 === void 0 ? void 0 : _vm$team24.disciplines, function (discipline) {
    return _c('team-discipline-members-card', {
      key: discipline.id,
      attrs: {
        "discipline": discipline,
        "show-edit-button": _vm.canEditMembers
      },
      on: {
        "editTeamMembersClick": _vm.handleEditTeamMembersClick
      }
    });
  }), 1)], 1), _c('b-row', [_c('b-col', [_vm.undefinedMembers && _vm.undefinedMembers.length > 0 ? _c('team-no-discipline-members-card', {
    attrs: {
      "members": _vm.undefinedMembers,
      "show-edit-button": _vm.canEditMembers,
      "team": _vm.team
    },
    on: {
      "editTeamMembersClick": _vm.handleEditTeamMembersClick
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('team-join-modal', {
    attrs: {
      "show-team-join-modal": _vm.showTeamJoinModal,
      "team": _vm.team
    },
    on: {
      "show-team-join-modal": _vm.handleShowTournamentJoinModalEvent
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }