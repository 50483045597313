import { Ability, AbilityBuilder } from '@casl/ability'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// const userData = JSON.parse(localStorage.getItem('userData'))

// export async function defineAbilitiesFor(user) {
//   const abilities = []
//
//   const user = await store.getters['user/getMe']
//
//   if (!user) {
//     return {
//       action: 'read',
//       subject: 'all',
//     }
//   }
//
//   if (data?.player) {
//     if (data?.player.id === user.id) {
//       abilities.push({
//         action: 'addFunds',
//         subject: 'Player',
//       })
//       abilities.push({
//         action: 'withdrawFunds',
//         subject: 'Player',
//       })
//       abilities.push({
//         action: 'edit',
//         subject: 'Player',
//       })
//     }
//   }
//
//   if (data?.team) {
//     // edit team
//     if (data?.team.members.some(member => member.user.id === user.id && member.role.title === 'Owner')) {
//       abilities.push({
//         action: 'edit',
//         subject: 'Team',
//       })
//     }
//
//     // edit team
//     if (data?.team.members.some(member => member.user.id === user.id && member.role.title === 'Owner')) {
//       abilities.push({
//         action: 'editMembers',
//         subject: 'Team',
//       })
//     }
//
//     // join team
//     if (!data?.team.members.some(member => member.user.id === user.id)) {
//       abilities.push({
//         action: 'join',
//         subject: 'Team',
//       })
//     }
//
//     // leave team
//     if (data?.team.members.some(member => member.user.id === user.id && member.role.title !== 'Owner')) {
//       abilities.push({
//         action: 'leave',
//         subject: 'Team',
//       })
//     }
//
//     // add funds
//     if (data?.team.members.some(member => member.user.id === user.id && member.role.title !== 'Owner')) {
//       abilities.push({
//         action: 'addFunds',
//         subject: 'Team',
//       })
//       abilities.push({
//         action: 'withdrawFunds',
//         subject: 'Team',
//       })
//     }
//   }
//
//   if (data?.tournament) {
//     if (data?.tournament?.registrations?.find(registration => {
//       if (registration.participant_type === TournamentParticipantType.TEAM) {
//         return user.teams.find(team => team.id === registration.participant_id && team.members.some(member => member.user.id === user.id && member.role.title !== 'Owner'))
//       }
//       return user.id === registration.participant_id
//     })) {
//       abilities.push({
//         action: 'cancelRegistration',
//         subject: 'Tournament',
//       })
//
//       abilities.push({
//         action: 'editLineup',
//         subject: 'Tournament',
//       })
//     }
//   }
//
//   return abilities
// }
//
// const initialAbilities = defineAbilitiesFor(null)
// export default new Ability(initialAbilities)

// eslint-disable-next-line import/prefer-default-export
export function defineAbilitiesFor(user) {
  const { can, build } = new AbilityBuilder(Ability)

  can('read', 'Dashboard', { ownerId: user?.id })
  //
  // if (user.role === 'admin') {
  //   can('manage', 'all') // 'manage' is a shortcut for any action
  // } else {
  //   can('read', 'Article')
  //   if (user.role === 'editor') {
  //     can('update', 'Article', { authorId: user.id })
  //   }
  //   cannot('delete', 'Article')
  // }

  return build()
}
//
// (async () => {
//   const currentUser = await store.getters['user/getMe']
//   // Export the ability instance
//   Vue.prototype.$ability = defineAbilitiesFor(currentUser)
// })()
