var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center pt-75 pb-25"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Trusted members roles ")])]), _c('b-card-body', [_c('div', [_c('ul', {
    staticClass: "list-unstyled my-1"
  }, _vm._l(_vm.members, function (member) {
    return _c('li', {
      key: member.id,
      staticClass: "pb-1"
    }, [_c('span', [_c('b-badge', {
      attrs: {
        "variant": "light-primary",
        "to": {
          name: 'player-profile',
          params: {
            id: member.user.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")])], 1), _c('span', {
      staticClass: "align-middle float-right"
    }, [_vm._v(_vm._s(member.role.title))])]);
  }), 0)]), _c('div', {
    staticClass: "text-center"
  }, [_vm.canEditMembers ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleEditMembersClick
    }
  }, [_vm._v(" edit members ")]) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }