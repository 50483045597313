const Disciplines = [
  {
    id: 1, title: 'Valorant', slug: 'valorant', image: 'images/games/valorant.png', category_id: 1,
  },
  {
    id: 6, title: 'League of Legends', slug: 'lol', image: 'images/games/lol.png', category_id: 1,
  },
  {
    id: 2, title: 'Fortnite', slug: 'fortnite', image: 'images/games/fortnite.png', category_id: 1,
  },
  {
    id: 7, title: 'Call of Duty', slug: 'call-of-duty', image: 'images/games/cod.png', category_id: 1,
  },
  {
    id: 3, title: 'Call of Duty 2', slug: 'call-of-duty-2', image: 'images/games/cod2.png', category_id: 1,
  },
  {
    id: 4, title: 'Call of Duty UO', slug: 'call-of-duty-uo', image: 'images/games/cod_uo.png', category_id: 1,
  },
  {
    id: 5, title: 'Counter Strike 2', slug: 'counter-strike-2', image: 'images/cs2.jpeg', category_id: 1,
  },
  {
    id: 8, title: 'Asetto Corsa Competizione', slug: 'asetto-corsa-competizione', image: 'images/games/asseto.png', category_id: 1,
  },
]

export default Disciplines
