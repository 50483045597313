var render = function () {
  var _vm$team;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "team-members-edit-modal",
      "title": _vm.title,
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hidden": _vm.handleCancelClick
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-row', {
          staticClass: "justify-content-between flex-grow-1"
        }, [_c('b-col', [_c('dismissible-message-box', {
          staticClass: "w-100 p-1",
          attrs: {
            "auto-dismiss": 6000,
            "show": _vm.showMessage,
            "variant": _vm.messageType
          },
          on: {
            "onDismissibleMessageBoxDismissed": function onDismissibleMessageBoxDismissed($event) {
              _vm.showMessage = false;
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoCircleIcon"
          }
        }), _vm._v(" " + _vm._s(_vm.message) + " ")], 1)], 1), _c('b-col', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      }
    }])
  }, [_c('b-row', {
    staticClass: "d-flex justify-space-around"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_vm.isTeamLoading ? _c('p', [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('b-table', {
    attrs: {
      "items": (_vm$team = _vm.team) === null || _vm$team === void 0 ? void 0 : _vm$team.members,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(role_id)",
      fn: function fn(data) {
        return [_c('v-select', {
          key: data.item.id,
          attrs: {
            "options": _vm.roles,
            "reduce": function reduce(role) {
              return role.code;
            },
            "label": "title"
          },
          model: {
            value: data.item.role.id,
            callback: function callback($$v) {
              _vm.$set(data.item.role, "id", $$v);
            },
            expression: "data.item.role.id"
          }
        })];
      }
    }, {
      key: "cell(user)",
      fn: function fn(data) {
        return [_c('span', [_vm._v(_vm._s(data.item.user.name))])];
      }
    }, {
      key: "cell(disciplinesLocal)",
      fn: function fn(data) {
        return [_c('v-select', {
          key: data.item.id,
          attrs: {
            "options": _vm.disciplineOptions,
            "reduce": function reduce(discipline) {
              return discipline.code;
            },
            "label": "title",
            "multiple": ""
          },
          model: {
            value: data.item.disciplines,
            callback: function callback($$v) {
              _vm.$set(data.item, "disciplines", $$v);
            },
            expression: "data.item.disciplines"
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveItem(row.item);
            }
          }
        }, [row.item.isSaving ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "16"
          }
        })], 1), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(row.item);
            }
          }
        }, [row.item.isDeleting ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon",
            "size": "16"
          }
        })], 1)], 1)];
      }
    }])
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('h5', [_vm._v("Invite new member")]), _c('validation-observer', {
    ref: "inviteEmailForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var invalid = _ref2.invalid;
        return [_c('b-form', {
          staticClass: "mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.handleInvite($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var validationErrors = _ref3.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "state": validationErrors && validationErrors.length > 0 ? false : null,
                  "name": "login-email",
                  "placeholder": "john@example.com"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(" " + _vm._s(validationErrors && validationErrors.length > 0 ? validationErrors[0] : '') + " ")])];
            }
          }], null, true)
        })], 1), _c('spinning-button', {
          attrs: {
            "disabled": invalid,
            "is-loading": _vm.isLoadingInvite,
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v(" Invite member ")])], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }