var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    attrs: {
      "block": _vm.block,
      "disabled": _vm.loading,
      "type": _vm.type,
      "variant": _vm.variant
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade"
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }) : _vm._e()], 1), _vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }