import { Timezones } from '@/constants/Timezones'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import format from 'date-fns/format'
import intervalToDuration from 'date-fns/intervalToDuration'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { differenceInDays, differenceInHours } from 'date-fns'

export const utcToTimezone = (date, timezoneId) => {
  // eslint-disable-next-line no-shadow
  const timezone = Timezones.find(timezone => timezone.id === timezoneId)

  let dateObject = new Date(date)
  if (timezone) {
    dateObject = utcToZonedTime(date, timezone.title)
  }

  return dateObject
}

export const utcToTimezoneFormat = (date, timezoneId = 37) => { // 'UTC'
  if (!date) {
    return ''
  }

  const dateObject = utcToTimezone(date, timezoneId)

  const formatter = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: 'medium',
    timeStyle: 'short',
  })

  return formatter.format(dateObject)
}

export const timeTillDate = (date, timezoneId) => {
  if (!date || !timezoneId) {
    return ''
  }

  if (new Date(date) < new Date()) return null

  const { days, hours, minutes } = intervalToDuration({
    start: utcToTimezone(date, timezoneId),
    end: new Date(),
  })

  const daysText = days > 0 ? `${days} days ` : ''
  const hoursText = days === 0 && hours > 0 ? `${hours} hours ` : ''
  const minutesText = days === 0 && hours === 0 && minutes > 0 ? `${minutes} minutes ` : ''

  return `${daysText}${hoursText}${minutesText}`
}

// eslint-disable-next-line no-unused-vars
function calculateTimeDifferenceWithText(date1, date2, userTimezone = null) {
  if (!userTimezone) {
    try {
      userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
  }

  let formattedDate1 = format(date1, 'yyyy-MM-dd HH:mm:ss')
  let formattedDate2 = format(date2, 'yyyy-MM-dd HH:mm:ss')

  if (userTimezone) {
    formattedDate1 = formatInTimeZone(date1, userTimezone, 'yyyy-MM-dd HH:mm:ss')
    formattedDate2 = formatInTimeZone(date2, userTimezone, 'yyyy-MM-dd HH:mm:ss')
  }

  const parsedDate1 = new Date(formattedDate1)
  const parsedDate2 = new Date(formattedDate2)

  const diffInMinutes = differenceInMinutes(parsedDate2, parsedDate1)

  if (diffInMinutes >= 60) {
    const diffInHours = differenceInHours(parsedDate2, parsedDate1)
    if (diffInHours >= 24) {
      const diffInDays = differenceInDays(parsedDate2, parsedDate1)
      return `${diffInDays} days`
    }

    return `${diffInHours} hours`
  }

  return `${diffInMinutes} minutes`
}

export const timeToMilliseconds = timeString => {
  if (!timeString) return null
  if (!timeString.includes(':')) return null
  if (!timeString.includes('.')) return null

  const parts = timeString.split(':')

  const secondsAndMilliseconds = parts[2].split('.')
  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(secondsAndMilliseconds[0], 10)
  const milliseconds = parseInt(secondsAndMilliseconds[1], 10)

  return (hours * 3600 + minutes * 60 + seconds) * 1000 + milliseconds
}

export const millisecondsToTime = milliseconds => {
  const hours = Math.floor(milliseconds / 3600000)
  milliseconds %= 3600000
  const minutes = Math.floor(milliseconds / 60000)
  milliseconds %= 60000
  const seconds = Math.floor(milliseconds / 1000)
  const msecs = milliseconds % 1000

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')
  const formattedMilliseconds = msecs.toString().padStart(3, '0')

  // Concatenate all parts to get the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`
}
