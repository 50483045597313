<template>
  <b-card no-body>
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">
        Team requests
      </h5>
    </b-card-header>

    <b-card-body>
      <b-row
        v-if="!invites || invites.length === 0"
        class="mt-1 align-items-center"
      >
        <b-col cols="12">
          <span class="font-italic">No pending invites.</span>
        </b-col>
      </b-row>

      <b-row
        v-for="invite in invites"
        :key="invite.id"
        class="mt-1 align-items-center"
      >
        <b-col cols="2">
          <b-avatar
            :src="invite.user.avatar_image"
            size="24"
          />
        </b-col>
        <b-col
          class="p-0"
          cols="6"
        >
          <h6 class="mb-0">
            {{ invite.user.name }}
          </h6>
        </b-col>
        <b-col cols="4">
          <team-response-to-player-request-modal
            :player="selectedInvite.user"
            :request-id="selectedInvite.id"
            @teamInvitationStatusChanged="handleTeamInvitationStatusChanged"
          />
          <b-button
            size="sm"
            variant="primary"
            class="font-small-1"
            @click="handleViewInviteClick(invite)"
          >
            View
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import TeamInviteType from '@/constants/TeamInviteType'
import TeamResponseToPlayerRequestModal from '@/views/team/TeamResponseToPlayerRequestModal.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAvatar,
    BRow,
    BCol,
    BButton,
    TeamResponseToPlayerRequestModal,
  },
  props: {
    team: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      TeamInviteType,
      selectedInvite: {
        user: null,
      },
      invites: [],
    }
  },
  watch: {},
  async mounted() {
    await this.fetchTeamInvites()
  },
  methods: {
    async fetchTeamInvites() {
      const { data } = await this.$apollo.query({
        query: gql`
          query teamInvites($teamId: Int!) {
            teamInvites(team_id: $teamId) {
              id
              user {
                name
                id
                avatar_image
              }
              type_id
            }
          }
        `,
        variables: {
          teamId: this.team.id,
        },
        fetchPolicy: 'no-cache',
      })

      this.invites = data?.teamInvites || []
      this.invites = this.invites.filter(
        invites => invites.type_id === TeamInviteType.request,
      )
    },
    async handleTeamInvitationStatusChanged() {
      await this.fetchTeamInvites()
    },
    handleViewInviteClick(invite) {
      this.selectedInvite = invite

      this.$bvModal.show('team-invite-player-modal')
    },
  },
}
</script>

<style></style>
