import { mapMutations } from 'vuex'

// eslint-disable-next-line import/prefer-default-export
export const breadcrumbManager = {
  methods: {
    ...mapMutations('breadcrumbs', {
      setBreadcrumbs: 'set',
      pushBreadcrumb: 'push',
      popBreadcrumb: 'pop',
      replaceBreadcrumb: 'replace',
      emptyBreadcrumbs: 'empty',
    }),
  },
}
