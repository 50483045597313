var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-alert', {
    attrs: {
      "variant": _vm.variant,
      "dismissible": _vm.dismissible,
      "fade": _vm.fade
    },
    on: {
      "dismissed": _vm.dismiss
    },
    model: {
      value: _vm.isMessageBoxVisible,
      callback: function callback($$v) {
        _vm.isMessageBoxVisible = $$v;
      },
      expression: "isMessageBoxVisible"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }