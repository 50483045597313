// eslint-disable-next-line import/prefer-default-export
import { MemberRoleType } from '@/constants/MemberRoleType'
import store from '@/store'

export const showLeaveTeamButton = team => {
  if (!team || !team.members || team.members.length === 0) {
    return false
  }

  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  return team.members.find(member => member.user_id === me.id)
}

export const showJoinTeamButton = team => {
  if (!team || !team.members || team.members.length === 0) {
    return true
  }

  const me = store.getters['user/getMe']

  if (!me) {
    return true // handle this case with redirect to login
  }

  return !team.members.find(member => member.user_id === me.id)
}

export const canEditTeam = team => {
  if (!team || !team.members || team.members.length === 0) {
    return false
  }

  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  return !!team.members.find(member => member.role.id === MemberRoleType.OWNER && member.user_id === me.id)
}

export const loggedUserIsMemberOfTeam = team => {
  if (!team || !team.members || team.members.length === 0) {
    return false
  }

  const me = store.getters['user/getMe']

  if (!me) {
    return false
  }

  return team.members.some(member => member.user_id === me.id)
}

export const loggedUserIsTeamOwnerOrLeader = team => {
  const me = store.getters['user/getMe']

  // is team owner or team leader of exact team
  const ownerOrLeader = team?.members.some(member => member.user.id === me.id && (
    member.role.id === MemberRoleType.TEAM_LEADER
    || member.role.id === MemberRoleType.OWNER
  ))

  // console.log('me-> owner | leader', ownerOrLeader)

  return ownerOrLeader
}
