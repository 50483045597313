<template>
  <b-card no-body>
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">
        Trusted members roles
      </h5>
    </b-card-header>

    <b-card-body>
      <div>
        <ul class="list-unstyled my-1">
          <li
            v-for="member in members"
            :key="member.id"
            class="pb-1"
          >
            <span><b-badge
              variant="light-primary"
              :to="{ name: 'player-profile', params: { id: member.user.id } }"
            > {{ member.user.name }} </b-badge></span>
            <span class="align-middle float-right">{{ member.role.title }}</span>
          </li>
        </ul>
      </div>

      <div class="text-center">
        <b-button
          v-if="canEditMembers"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="outline-primary"
          @click="handleEditMembersClick"
        >
          edit members
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    canEditMembers: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

  },
  methods: {
    handleEditMembersClick() {
      this.$emit('editTeamMembersClick')
    },
  },
}
</script>

<style></style>
