<template>
  <b-card no-body>
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <b-media
        class="align-items-center"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            :src="getDisciplineImage(discipline?.discipline?.id) || null"
            square
            size="36"
          />
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ discipline?.discipline?.title }}
          </h5>
        </b-media-body>
      </b-media>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div
            v-if="!discipline?.members || discipline?.members.length === 0"
            class="font-italic mt-1"
          >
            {{
              Math.random() > 0.5 ? 'Start recruiting awesome members and build a championship squad.' : 'Ready to find your crew? Start building your team and connect with amazing players.'
            }}
          </div>

          <vue-perfect-scrollbar
            v-else
            class="scroll-area"
            style="height: 150px;"
          >
            <div>
              <b-badge
                v-for="member in discipline?.members"
                :key="member.id"
                :to="{ name: 'player-profile', params: { id: member.user.id } }"
                class="mt-1 mr-1"
                variant="light-primary"
              >
                {{ member.user.name }}
              </b-badge>
            </div>
          </vue-perfect-scrollbar>
        </b-col>
      </b-row>
      <b-row v-if="showEditButton">
        <b-col class="text-center">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-1"
              size="sm"
              variant="outline-primary"
              @click="handleEditMembersClick"
            >
              edit members
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { getDisciplineImage } from '@/views/common'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BAvatar,
    BMedia,
    BMediaBody,
    BButton,
    BMediaAside,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  props: {
    discipline: {
      type: Object,
      required: true,
    },
    showEditButton: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {},
  methods: {
    getDisciplineImage,
    handleEditMembersClick() {
      this.$emit('editTeamMembersClick')
    },
  },
}
</script>
<style lang="scss">
.badge {
  min-width: 60px;
  padding: 7px;
}
</style>
