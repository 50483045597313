// eslint-disable-next-line import/prefer-default-export
import Disciplines from '@/constants/Disciplines'

// eslint-disable-next-line import/prefer-default-export
export const getDisciplineImage = disciplineId => {
  const image = Disciplines.find(d => d.id === disciplineId)?.image

  return image ? `/${image}` : null
}

export const getUserLocale = () => (navigator.languages && navigator.languages.length
  ? navigator.languages[0]
  : navigator.language)
