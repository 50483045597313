<template>
  <b-button
    :block="block"
    :disabled="loading"
    :type="type"
    :variant="variant"
  >
    <div class="d-flex align-items-center justify-content-center">
      <transition
        mode="out-in"
        name="fade"
      >
        <b-spinner
          v-if="isLoading"
          small
          class="mr-1"
        />
      </transition>
      <slot />
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: 'submit',
    },
    block: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    variants() {
      return this.variant
    },
    loading() {
      return this.isLoading
    },
  },
}
</script>
<style lang="scss"></style>
