var render = function () {
  var _vm$team2, _vm$team3, _vm$team4, _vm$team4$name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "teamJoinModal",
    attrs: {
      "id": "team-join-modal",
      "visible": _vm.showTeamJoinModal,
      "body-class": "p-0",
      "static": "",
      "centered": ""
    },
    on: {
      "change": function change(val) {
        return _vm.$emit('update:show-team-join-modal', val);
      },
      "close": _vm.handleCancelClick
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        var _vm$team;
        return [_vm.showSuccess || _vm.showError ? _c('b-row', [_c('dismissible-message-box', {
          staticClass: "w-100 p-1",
          attrs: {
            "variant": _vm.MessageType.ERROR,
            "show": _vm.showError
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Something went wrong. Please try again or contact our support ")], 1), _c('dismissible-message-box', {
          staticClass: "w-100 p-1",
          attrs: {
            "show": _vm.showSuccess,
            "variant": _vm.MessageType.SUCCESS
          },
          on: {
            "message-box-dismissed": function messageBoxDismissed($event) {
              return _vm.emit('show-team-join-modal', false);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "InfoIcon"
          }
        }), _vm._v(" Your successfully send a request to join team " + _vm._s((_vm$team = _vm.team) === null || _vm$team === void 0 ? void 0 : _vm$team.name) + " ")], 1)], 1) : _vm._e(), _c('b-row', {
          staticClass: "justify-content-between flex-grow-1"
        }, [_c('spinning-button', {
          staticClass: "ml-1",
          attrs: {
            "is-loading": _vm.isSubmitting,
            "variant": "primary"
          },
          nativeOn: {
            "click": function click($event) {
              return _vm.handleJoinClick($event);
            }
          }
        }, [_vm._v(" Request to join ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.handleCancelClick
          }
        }, [_vm._v(" Close ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('h4', [_vm._v(" Join team "), _c('b', [_vm._v(_vm._s((_vm$team2 = _vm.team) === null || _vm$team2 === void 0 ? void 0 : _vm$team2.name))]), _vm._v("? ")])]), _c('div', [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$team3 = _vm.team) === null || _vm$team3 === void 0 ? void 0 : _vm$team3.avatar_image,
      "text": (_vm$team4 = _vm.team) === null || _vm$team4 === void 0 ? void 0 : (_vm$team4$name = _vm$team4.name) === null || _vm$team4$name === void 0 ? void 0 : _vm$team4$name.substring(0, 2),
      "variant": "light-primary",
      "size": "104px"
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }