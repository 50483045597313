<template>
  <b-modal
    id="team-members-edit-modal"
    :title="title"
    no-close-on-backdrop
    size="xl"
    @hidden="handleCancelClick"
  >
    <template #modal-footer="{ cancel }">
      <b-row class="justify-content-between flex-grow-1">
        <b-col>
          <dismissible-message-box
            :auto-dismiss="6000"
            :show="showMessage"
            :variant="messageType"
            class="w-100 p-1"
            @onDismissibleMessageBoxDismissed="showMessage = false"
          >
            <feather-icon
              class="mr-50"
              icon="InfoCircleIcon"
            />
            {{ message }}
          </dismissible-message-box>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            @click="cancel"
          >
            Close
          </b-button>
        </b-col>
      </b-row>

    </template>

    <b-row class="d-flex justify-space-around">
      <!-- Edit member roles -->
      <b-col
        sm="12"
      >
        <p v-if="isTeamLoading">
          <b-spinner label="Loading..." />
        </p>

        <b-table
          v-else
          :items="team?.members"
          :fields="fields"
        >
          <template v-slot:cell(role_id)="data">
            <v-select
              :key="data.item.id"
              v-model="data.item.role.id"
              :options="roles"
              :reduce="role => role.code"
              label="title"
            />
          </template>

          <template v-slot:cell(user)="data">
            <span>{{ data.item.user.name }}</span>
          </template>

          <template v-slot:cell(disciplinesLocal)="data">
            <v-select
              :key="data.item.id"
              v-model="data.item.disciplines"
              :options="disciplineOptions"
              :reduce="discipline => discipline.code"
              label="title"
              multiple
            />
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-end">
              <b-button
                size="sm"
                class="mr-1"
                variant="primary"
                @click="saveItem(row.item)"
              >
                <b-spinner
                  v-if="row.item.isSaving"
                  small
                />
                <feather-icon
                  v-else
                  icon="CheckCircleIcon"
                  size="16"
                />
              </b-button>

              <b-button
                size="sm"
                class="mr-1"
                variant="danger"
                @click="deleteItem(row.item)"
              >
                <b-spinner
                  v-if="row.item.isDeleting"
                  small
                />
                <feather-icon
                  v-else
                  icon="XCircleIcon"
                  size="16"
                />
              </b-button>

            </div>
          </template>
        </b-table>

      </b-col>

    </b-row>

    <hr>

    <b-row class="mt-1">
      <!-- Invite members -->
      <b-col
        sm="12"
        md="4"
      >
        <h5>Invite new member</h5>
        <validation-observer
          ref="inviteEmailForm"
          v-slot="{ invalid }"
        >
          <b-form
            class="mt-2"
            @submit.prevent="handleInvite"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                v-slot="{ errors: validationErrors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  :state="
                    validationErrors && validationErrors.length > 0
                      ? false
                      : null
                  "
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">
                  {{
                    validationErrors && validationErrors.length > 0
                      ? validationErrors[0]
                      : ''
                  }}
                </small>
              </validation-provider>
            </b-form-group>

            <spinning-button
              :disabled="invalid"
              :is-loading="isLoadingInvite"
              type="submit"
              variant="primary"
            >
              Invite member
            </spinning-button>
          </b-form>
        </validation-observer>

      </b-col>
    </b-row>

  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput, BModal,
  BRow, BSpinner, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import SpinningButton from '@/components/SpinningButton.vue'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'

export default {
  components: {
    DismissibleMessageBox,
    BSpinner,
    BTable,
    BModal,
    SpinningButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      MessageType,
      fields: [{
        key: 'user',
        label: 'member',
      }, {
        key: 'role_id',
        label: 'role',
      }, {
        key: 'disciplinesLocal',
        label: 'disciplines',
      }, 'actions'],
      team: null,
      roles: [{
        code: 1,
        title: 'Owner',
      },
      {
        code: 2,
        title: 'Team lead',
      },
      {
        code: 3,
        title: 'Team sub-lead',
      },
      {
        code: 4,
        title: 'Player',
      }],
      showMessage: false,
      messageType: null,
      message: null,
      isLoadingInvite: false,
      userEmail: null,
      isTeamLoading: false,
      dataChanges: false,
    }
  },
  computed: {
    title() {
      return `Edit ${this.team?.name} members`
    },
    disciplineOptions() {
      return this.team?.disciplines.map(discipline => ({
        code: discipline.id,
        title: discipline.discipline.title,
      }))
    },
  },
  async created() {
    this.isTeamLoading = true

    const { data } = await this.$api.team.teamById(Number(this.$route.params.id))

    this.isTeamLoading = false

    if (data) {
      this.team = {
        ...data,
        members: data.members.map(member => ({
          ...member,
          disciplines: member.disciplines.map(discipline => discipline.team_discipline_id),
          isSaving: false,
          isDeleting: false,
        })),
      }
    }
  },
  methods: {
    handleCancelClick() {
      if (this.dataChanges) {
        this.$emit('onTeamMemberEdited')
      }
    },
    async saveItem(item) {
      this.message = null
      this.showMessage = false

      // eslint-disable-next-line no-param-reassign
      item.isSaving = true

      const { errors } = await this.$api.team.updateTeamMember(
        item.id,
        item?.role?.id || null,
        item.disciplines,
      )

      if (!errors) {
        this.showMessage = true
        this.messageType = MessageType.SUCCESS
        this.message = 'Member updated successfully'

        this.dataChanges = true
      } else {
        this.showMessage = true
        this.messageType = MessageType.ERROR
        this.message = 'There was an error updating the member'
      }

      // eslint-disable-next-line no-param-reassign
      item.isSaving = false
    },
    async deleteItem(item) {
      this.message = null
      this.showMessage = false

      // eslint-disable-next-line no-param-reassign
      item.isDeleting = true

      const { errors } = await this.$api.team.deleteTeamMember(
        item.id,
      )

      if (!errors) {
        this.showMessage = true
        this.messageType = MessageType.SUCCESS
        this.message = 'Member successfully removed'

        this.dataChanges = true

        this.team.members = this.team.members.filter(member => member.id !== item.id)
      } else {
        this.showMessage = true
        this.messageType = MessageType.ERROR
        this.message = 'There was an error removing the member'
      }

      // eslint-disable-next-line no-param-reassign
      item.isDeleting = false
    },
    async handleInvite() {
      this.message = null
      this.showMessage = false

      const formValid = this.$refs.inviteEmailForm.validate()
      if (!formValid) {
        return
      }

      this.isLoadingInvite = true

      const { errors } = await this.$api.team.inviteUserToTeam(
        this.userEmail,
        Number(this.$route.params.id),
      )

      this.isLoadingInvite = false

      if (!errors) {
        this.message = 'User invited successfully'
        this.showMessage = true
        this.messageType = MessageType.SUCCESS
      } else {
        this.message = 'There was an error inviting the user'
        this.messageType = MessageType.ERROR
        this.showMessage = true
      }
    },
    handleCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
