var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center pt-75 pb-25"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" Team requests ")])]), _c('b-card-body', [!_vm.invites || _vm.invites.length === 0 ? _c('b-row', {
    staticClass: "mt-1 align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "font-italic"
  }, [_vm._v("No pending invites.")])])], 1) : _vm._e(), _vm._l(_vm.invites, function (invite) {
    return _c('b-row', {
      key: invite.id,
      staticClass: "mt-1 align-items-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('b-avatar', {
      attrs: {
        "src": invite.user.avatar_image,
        "size": "24"
      }
    })], 1), _c('b-col', {
      staticClass: "p-0",
      attrs: {
        "cols": "6"
      }
    }, [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(invite.user.name) + " ")])]), _c('b-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('team-response-to-player-request-modal', {
      attrs: {
        "player": _vm.selectedInvite.user,
        "request-id": _vm.selectedInvite.id
      },
      on: {
        "teamInvitationStatusChanged": _vm.handleTeamInvitationStatusChanged
      }
    }), _c('b-button', {
      staticClass: "font-small-1",
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.handleViewInviteClick(invite);
        }
      }
    }, [_vm._v(" View ")])], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }