<template>
  <ul class="social-links list-unstyled">
    <li
      v-for="link in links"
      :key="link.id"
    >
      <b-link
        :href="link.url"
        target="_blank"
      >
        <b-img
          v-if="link.link_type_id === LinkType.FACEBOOK"
          :src="require(`@/assets/images/logo/facebook.png`)"
          alt="Facebook"
          height="30"
          width="30"
        />

        <b-img
          v-if="link.link_type_id === LinkType.INSTAGRAM"
          :src="require(`@/assets/images/logo/instagram.png`)"
          alt="Instagram"
          height="30"
          width="30"
        />

        <b-img
          v-if="link.link_type_id === LinkType.DISCORD"
          :src="require(`@/assets/images/logo/discord.png`)"
          alt="Discord"
          height="30"
          width="30"
        />

        <b-img
          v-if="link.link_type_id === LinkType.TWITTER"
          :src="require(`@/assets/images/logo/twitter.png`)"
          alt="Twitter"
          height="30"
          width="30"
        />
      </b-link>
    </li>
  </ul>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'
import LinkType from '@/constants/LinkType'

export default {
  components: {
    BImg,
    BLink,
  },
  props: {
    links: {
      type: Array,
      default: () => null,
    },
  },
  setup() {
    return {
      LinkType,
    }
  },
}
</script>

<style lang="scss">
.social-links {
  display: flex;
  padding-top: 5px;

  li {
    padding-right: 10px;
  }
}
</style>
