var render = function () {
  var _vm$discipline, _vm$discipline$discip, _vm$discipline2, _vm$discipline2$disci, _vm$discipline3, _vm$discipline4, _vm$discipline5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-between align-items-center pt-75 pb-25"
  }, [_c('b-media', {
    staticClass: "align-items-center",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-1"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.getDisciplineImage((_vm$discipline = _vm.discipline) === null || _vm$discipline === void 0 ? void 0 : (_vm$discipline$discip = _vm$discipline.discipline) === null || _vm$discipline$discip === void 0 ? void 0 : _vm$discipline$discip.id) || null,
      "square": "",
      "size": "36"
    }
  })], 1), _c('b-media-body', [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s((_vm$discipline2 = _vm.discipline) === null || _vm$discipline2 === void 0 ? void 0 : (_vm$discipline2$disci = _vm$discipline2.discipline) === null || _vm$discipline2$disci === void 0 ? void 0 : _vm$discipline2$disci.title) + " ")])])], 1)], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [!((_vm$discipline3 = _vm.discipline) !== null && _vm$discipline3 !== void 0 && _vm$discipline3.members) || ((_vm$discipline4 = _vm.discipline) === null || _vm$discipline4 === void 0 ? void 0 : _vm$discipline4.members.length) === 0 ? _c('div', {
    staticClass: "font-italic mt-1"
  }, [_vm._v(" " + _vm._s(Math.random() > 0.5 ? 'Start recruiting awesome members and build a championship squad.' : 'Ready to find your crew? Start building your team and connect with amazing players.') + " ")]) : _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    staticStyle: {
      "height": "150px"
    }
  }, [_c('div', _vm._l((_vm$discipline5 = _vm.discipline) === null || _vm$discipline5 === void 0 ? void 0 : _vm$discipline5.members, function (member) {
    return _c('b-badge', {
      key: member.id,
      staticClass: "mt-1 mr-1",
      attrs: {
        "to": {
          name: 'player-profile',
          params: {
            id: member.user.id
          }
        },
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")]);
  }), 1)])], 1)], 1), _vm.showEditButton ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleEditMembersClick
    }
  }, [_vm._v(" edit members ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }