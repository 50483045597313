<template>
  <b-alert
    v-model="isMessageBoxVisible"
    :variant="variant"
    :dismissible="dismissible"
    :fade="fade"
    @dismissed="dismiss"
  >
    <slot />
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
  props: {
    variant: {
      type: String,
      default: 'info',
    },
    show: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    autoDismiss: {
      type: Number,
      default: 3000,
    },
    fade: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMessageBoxVisible: this.show,
    }
  },
  watch: {
    show: {
      handler(newValue) {
        this.isMessageBoxVisible = newValue

        if (newValue && this.autoDismiss > 0) {
          setTimeout(() => {
            this.hideMessageBox()
          }, this.autoDismiss)
        }
      },
    },
  },
  methods: {
    dismiss() {
      this.$emit('onDismissibleMessageBoxDismissed')
    },
    hideMessageBox() {
      this.isMessageBoxVisible = false

      this.$emit('onDismissibleMessageBoxDismissed')
    },
  },
}
</script>
