// eslint-disable-next-line
import jwt from 'jsonwebtoken'
import {
  CHANGE_PASSWORD, LOGIN, REGISTER, VERIFY_EMAIL,
} from '@/api/graphql/auth'
import { apolloClient } from '@/libs/apollo'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  // eslint-disable-next-line class-methods-use-this
  getToken() {
    return this.$cookies.get('access_token') || null
  }

  // eslint-disable-next-line class-methods-use-this
  setToken(value) {
    window.$cookies.set(
      'access_token',
      value,
      60 * 60 * 24 * 14,
      '/',
      process.env.NODE_ENV === 'development' ? 'localhost' : process.env.VUE_APP_BASE_DOMAIN, // domain
      process.env.NODE_ENV !== 'development', // secure
      'Strict', // samesite
    )
  }

  // eslint-disable-next-line class-methods-use-this
  isExpired() {
    const accessToken = window.$cookies.get('access_token')

    if (!accessToken) {
      return false
    }

    const decodedToken = jwt.decode(accessToken)

    const expired = !(
      decodedToken && decodedToken.exp * 1000 > new Date().getTime()
    )

    if (expired) {
      window.$cookies.remove('access_token')
    }

    return expired
  }

  loggedInUserId = () => {
    const accessToken = window.$cookies.get('access_token')

    if (!accessToken || this.isExpired()) {
      return false
    }

    const { user } = jwt.decode(accessToken)

    return user?.id ? Number(user.id) : null
  }

  // eslint-disable-next-line class-methods-use-this
  async verifyEmail(payload) {
    const {
      data,
      errors,
    } = await apolloClient
      .mutate({
        mutation: VERIFY_EMAIL,
        variables: {
          id: payload.hash,
          hash: payload.hash,
        },
      })
      // eslint-disable-next-line no-shadow
      .catch(errors => {
        console.log(errors)
        return {
          success: false,
          errors: errors.graphQLErrors,
        }
      })

    return {
      success: !!data?.verifyEmail,
      data: null,
      errors,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async login(email, password) {
    const { data, errors } = await apolloClient.mutate({
      mutation: LOGIN,
      variables: {
        email,
        password,
      },
    })
      .catch(err => {
        console.log(err)
        return { success: false, errors: err.graphQLErrors }
      })

    // eslint-disable-next-line no-unreachable
    if (data?.login?.access_token) {
      this.setToken(data.login.access_token)
    }

    return {
      success: !!data?.login,
      data: data?.login,
      errors,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async changePassword(token, email, password, repeatPassword) {
    const { data, errors } = await apolloClient.mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        email,
        password,
        token,
        repeatPassword,
      },
    })
      .catch(err => {
        console.log(err)
        return { success: false, errors: err.graphQLErrors }
      })

    return {
      success: !!data?.changePasswordWithToken,
      data: data?.changePasswordWithToken,
      errors,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async register(payload) {
    const {
      data,
      errors,
    } = await apolloClient
      .mutate({
        mutation: REGISTER,
        variables: {
          name: payload.name,
          email: payload.regEmail,
          password: payload.password,
          repeatPassword: payload.repeatPassword,
        },
      })
      // eslint-disable-next-line no-shadow
      .catch(errors => {
        console.log(errors)
        return {
          success: false,
          errors: errors.graphQLErrors,
        }
      })

    return {
      success: !!data?.register,
      data: null,
      errors,
    }
  }
}
