var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "d-flex justify-content-center align-items-center pt-75 pb-25"
  }, [_c('h5', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.left",
      value: "Other team members which don't take part in any game",
      expression: "`Other team members which don't take part in any game`",
      modifiers: {
        "hover": true,
        "left": true
      }
    }],
    staticClass: "mb-0"
  }, [_vm._v(" Other members ")])]), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.members || _vm.members.length === 0 ? _c('div', {
    staticClass: "font-italic mt-1"
  }, [_vm._v(" No members yet ")]) : _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    staticStyle: {
      "height": "150px"
    }
  }, [_c('div', _vm._l(_vm.members, function (member) {
    return _c('b-badge', {
      key: member.id,
      staticClass: "mt-1 mr-1",
      attrs: {
        "to": {
          name: 'player-profile',
          params: {
            id: member.user.id
          }
        },
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(member.user.name) + " ")]);
  }), 1)])], 1)], 1), _vm.showEditButton ? _c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleEditMembersClick
    }
  }, [_vm._v(" edit members ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }