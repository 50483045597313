<template>
  <div>
    <team-members-edit-modal @onTeamMemberEdited="handleTeamMemberEdited" />

    <b-row v-if="isLoading">
      <b-col class="text-center">
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col
        lg="9"
        md="12"
      >
        <b-row>
          <b-col>
            <!-- Team card -->
            <b-card
              no-body
              class="p-2"
            >
              <b-row v-if="isLoading">
                <b-col class="text-center">
                  <b-spinner label="Loading..." />
                </b-col>
              </b-row>

              <b-row v-if="team">
                <!-- Profile info -->
                <b-col
                  lg="6"
                  md="12"
                >
                  <!-- Team name -->
                  <b-row class="flex-nowrap">
                    <!-- Team Avatar -->
                    <b-col
                      md="3"
                      lg="4"
                    >
                      <b-avatar
                        :src="team?.avatar_image"
                        :text="team?.name?.substring(0, 2)"
                        :variant="`light-primary`"
                        rounded
                        size="120px"
                      />
                    </b-col>

                    <b-col
                      md="9"
                    >
                      <b-row>
                        <b-col class="d-flex align-items-start">
                          <h4 class="mb-0 mr-1">
                            {{ team?.name }}
                          </h4>
                          <span
                            class="ml-1"
                          >
                            <b-button
                              v-if="canEdit"
                              class="mb-1"
                              size="sm"
                              variant="primary"
                              @click="handleEditClick"
                            >
                              Edit
                            </b-button>
                          </span>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <div
                            v-if="1===2"
                            class="mb-0"
                          >
                            {{ team?.email }}
                          </div>
                        </b-col>
                      </b-row>

                      <!-- social links -->
                      <b-row>
                        <b-col class="mb-1 mr-1">
                          <social-links-list :links="team?.links" />
                        </b-col>
                      </b-row>

                      <!-- Join team -->
                      <b-row class="flex-column">
                        <b-col
                          v-if="canJoin"
                          lg="6"
                          md="3"
                        >
                          <b-button
                            class="mb-1  w-100"
                            size="sm"
                            variant="primary"
                            @click.prevent="handleTeamJoinModalShow"
                          >
                            Join team
                          </b-button>
                        </b-col>

                        <b-col
                          v-if="pendingApproval"
                          lg="6"
                          md="3"
                        >
                          <b-button
                            class="mb-1 w-100"
                            size="sm"
                            variant="secondary"
                            disabled="disabled"
                          >
                            Waiting approval
                          </b-button>
                        </b-col>

                        <b-col
                          v-if="canLeave"
                          lg="6"
                          md="3"
                        >
                          <b-button
                            class="mb-1 w-100"
                            size="sm"
                            variant="primary"
                            @click.prevent="handleLeaveTeamClick"
                          >
                            Leave team
                          </b-button>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                  <!-- /Team name -->

                  <!-- Wallet -->
                  <b-row class="flex-nowrap mt-1">
                    <b-col
                      md="3"
                      lg="4"
                    >
                      <b-badge
                        class="p-1 font-medium-2 w-100"
                        style="max-width: 120px"
                        variant="light-success"
                      >
                        {{ team?.balance || 0 }} €
                      </b-badge>
                    </b-col>

                    <b-col
                      md="9"
                    >
                      <b-row class="flex-column">
                        <b-col
                          lg="6"
                          md="3"
                        >
                          <b-button
                            v-if="canSendFunds"
                            class="mb-1 font-small-1"
                            disabled="disabled"
                            size="sm"
                            variant="primary"
                          >
                            Send funds
                          </b-button>
                        </b-col>

                        <b-col
                          lg="6"
                          md="3"
                        >
                          <b-button
                            v-if="canWithdrawFunds"
                            :to="{ name: 'apps-users-edit', params: { id: team?.id } }"
                            class="mb-1 font-small-1"
                            disabled="disabled"
                            size="sm"
                            variant="primary"
                          >
                            Withdraw funds
                          </b-button>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                  <!-- /Wallet -->
                </b-col>
                <!-- /Profile info -->

                <!-- Upcomming tournaments -->
                <b-col
                  lg="6"
                  md="12"
                >
                  <div>
                    <div class="mb-1 mt-1 mt-lg-0">
                      <h5>Upcoming and live tournaments</h5>
                    </div>

                    <div v-if="team?.upcoming_tournaments?.length === 0">
                      <span class="font-italic">The team's energy levels seem to be down lately</span> 🛏️ .
                    </div>

                    <app-timeline
                      v-if="
                        team &&
                          team.upcoming_tournaments &&
                          team.upcoming_tournaments.length > 0
                      "
                    >
                      <vue-perfect-scrollbar
                        class="scrollable-container media-list scroll-area"
                        tagname="li"
                      >
                        <app-timeline-item
                          v-for="tournament of team?.upcoming_tournaments"
                          :key="tournament.id"
                          :title="tournament.name"
                          :time="timeTillTournamentStart(tournament)"
                          variant="info"
                          class="ml-1"
                        />
                      </vue-perfect-scrollbar>
                    </app-timeline>
                  </div>
                </b-col>
                <!-- /Profile connections -->
              </b-row>

            </b-card>
            <!-- /Team card -->
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- Team tournaments & achievements -->
            <b-card
              class="p-1"
              no-body
            >
              <b-tabs pills>
                <b-tab
                  active
                  title="Tournaments"
                >
                  <p
                    v-if="!team?.past_tournaments || team?.past_tournaments.length === 0"
                    class="font-italic p-2"
                  >
                    Exciting tournaments are coming soon! Stay tuned for the action.
                  </p>
                  <vue-perfect-scrollbar
                    v-if="team?.past_tournaments && team?.past_tournaments?.length > 0"
                    class="scroll-area"
                    style="max-height: 200px"
                  >
                    <ul
                      class="d-flex flex-column align-items-start list-unstyled"
                    >
                      <li
                        v-for="(tournament, index) in team?.past_tournaments"
                        :key="index"
                        class="m-1"
                      >
                        <b-link
                          :to="{name: 'tournament-info', params: {id: tournament.id, game: tournament?.discipline?.slug}}"
                        >
                          <span
                            :title="tournamentStatusTitle(tournament.status_id)"
                            :class="`bullet bullet-sm mr-1 bullet-${tournamentStatusColor(tournament.status_id)}`"
                          /> <span :title="tournamentStatusTitle(tournament.status_id)">{{ tournament.name }}</span>
                        </b-link>

                      </li>
                    </ul>
                  </vue-perfect-scrollbar>

                </b-tab>
                <b-tab title="Achievements">
                  <ul
                    v-if="team?.achievements?.length > 0"
                    class="d-flex m-0 p-0 align-items-center flex-wrap p-2"
                  >
                    <li
                      v-for="(achievement, index) in team?.achievements"
                      :key="index"
                    >
                      {{ achievement.name }}
                    </li>
                  </ul>
                  <p
                    v-if="!team?.achievements || team?.achievements?.length === 0"
                    class="font-italic p-2"
                  >
                    Get ready to fill up this space with trophies and triumphs!
                  </p>
                </b-tab>
                <b-tab title="History">
                  <ul
                    v-if="team?.history?.length > 0"
                    class="d-flex m-0 p-0 align-items-center flex-wrap p-2"
                  >
                    <li
                      v-for="(history, index) in team?.history"
                      :key="index"
                    >
                      {{ history.name }}
                    </li>
                  </ul>
                  <p
                    v-if="!team?.history"
                    class="font-italic p-2"
                  >
                    No history yet.
                  </p>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>

      </b-col>

      <!-- Right side cards -->
      <b-col>
        <b-row v-if="isMyTeam && team?.members?.length > 0">
          <b-col>
            <team-trusted-members-roles-card
              :members="trustedMembers"
              :can-edit-members="canEditMembers"
              @editTeamMembersClick="handleEditTeamMembersClick"
            />
          </b-col>
        </b-row>

        <b-row v-if="canEdit && team?.invites?.length > 0">
          <b-col>
            <team-join-request-card
              :team="team"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <team-discipline-members-card
              v-for="discipline in team?.disciplines"
              :key="discipline.id"
              :discipline="discipline"
              :show-edit-button="canEditMembers"
              @editTeamMembersClick="handleEditTeamMembersClick"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <team-no-discipline-members-card
              v-if="undefinedMembers && undefinedMembers.length > 0"
              :members="undefinedMembers"
              :show-edit-button="canEditMembers"
              :team="team"
              @editTeamMembersClick="handleEditTeamMembersClick"
            />
          </b-col>
        </b-row>
        <!-- /Right side cards -->
      </b-col>
    </b-row>
    <team-join-modal
      :show-team-join-modal="showTeamJoinModal"
      :team="team"
      @show-team-join-modal="handleShowTournamentJoinModalEvent"
    />
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BSpinner, BTab, BTabs,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import SocialLinksList from '@/views/common/SocialLinksList.vue'
import TeamJoinModal from '@/views/team/TeamJoinModal.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  timeTillTournamentStart,
  tournamentStatusColor,
  tournamentStatusTitle,
} from '@/views/tournament/tournament-utils'
import TeamDisciplineMembersCard from '@/views/team/TeamDisciplineMembersCard.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import TeamNoDisciplineMembersCard from '@/views/team/TeamNoDisciplineMembersCard.vue'
import {
  canEditTeam, loggedUserIsMemberOfTeam, loggedUserIsTeamOwnerOrLeader, showLeaveTeamButton,
} from '@/views/team/team-utils'
import { isUserLoggedIn } from '@/auth/utils'
import { defineAbilitiesFor } from '@/libs/acl/ability'
import { Ability } from '@casl/ability'
import TeamMembersEditModal from '@/views/team/TeamMembersEditModal.vue'
import TeamJoinRequestCard from '@/views/team/TeamJoinRequestCard.vue'
import TeamTrustedMembersRolesCard from '@/views/team/TeamTrustedMembersRolesCard.vue'
import { MemberRoleType } from '@/constants/MemberRoleType'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'

export default {
  components: {
    TeamTrustedMembersRolesCard,
    TeamJoinRequestCard,
    TeamDisciplineMembersCard,
    TeamNoDisciplineMembersCard,
    BLink,
    BTabs,
    BTab,
    AppTimeline,
    AppTimelineItem,
    TeamJoinModal,
    BSpinner,
    SocialLinksList,
    BAvatar,
    BBadge,
    BCard,
    BButton,
    VuePerfectScrollbar,
    BCol,
    BRow,
    TeamMembersEditModal,
  },
  mixins: [breadcrumbManager],
  data() {
    return {
      isLoading: false,
      team: {},
      showTeamJoinModal: false,
      undefinedMembers: null,
      ability: new Ability(),
    }
  },
  computed: {
    trustedMembers() {
      const trustedRoles = [MemberRoleType.TEAM_LEADER, MemberRoleType.OWNER]

      return this.team?.members?.filter(
        member => trustedRoles.indexOf(Number(member.role.id)) !== -1,
      )
    },
    isMyTeam() {
      return loggedUserIsMemberOfTeam(this.team)
    },
    canEdit() {
      return canEditTeam(this.team)
    },
    canEditMembers() {
      return canEditTeam(this.team)
    },
    canJoin() {
      const pendingApproval = this.me?.team_requests?.some(tr => tr.team.id === Number(this.$route.params.id))

      return !pendingApproval && !loggedUserIsMemberOfTeam(this.team)
    },

    canLeave() {
      return loggedUserIsMemberOfTeam(this.team) && !loggedUserIsTeamOwnerOrLeader(this.team)
    },

    canSendFunds() {
      return this.ability.can('addFunds', 'Team')
    },

    canWithdrawFunds() {
      return this.ability.can('withdrawFunds', 'Team')
    },
    pendingApproval() {
      return this.me?.team_requests?.some(tr => tr.team.id === Number(this.$route.params.id))
    },
    me() {
      return this.$store.getters['user/getMe']
    },
  },
  async mounted() {
    await this.fetchTeam()
  },
  methods: {
    async fetchTeam() {
      this.isLoading = true

      const { id } = this.$route.params

      const {
        data,
        success,
      } = await this.$api.team.teamById(id)

      this.isLoading = false

      if (success === false) {
        return
      }
      this.team = data

      const abilities = await defineAbilitiesFor({ team: this.team })

      this.ability.update(abilities)

      // filter members that does not have defined discipline
      this.undefinedMembers = this.team.members.filter(member => !this.team.disciplines.some(discipline => discipline.members.some(disciplineMember => disciplineMember.user.id === member.user_id)))

      this.replaceBreadcrumb({
        find: 'teamName',
        replace: { text: this.team?.name, loading: false },
      })
    },
    showLeaveTeamButton,
    tournamentStatusColor,
    tournamentStatusTitle,
    timeTillTournamentStart,
    handleEditClick() {
      this.$router.push({
        name: 'team-edit',
        params: {
          id: this.team.id,
        },
      })
    },
    async handleTeamMemberEdited() {
      await this.fetchTeam()
    },
    handleEditTeamMembersClick() {
      this.$bvModal.show('team-members-edit-modal')
    },
    handleShowTournamentJoinModalEvent(val) {
      this.showTeamJoinModal = val
    },
    handleTeamJoinModalShow() {
      if (!isUserLoggedIn()) {
        this.$router.push({
          name: 'login',
        })
      }

      this.showTeamJoinModal = true
    },
    handleLeaveTeamClick() {
      this.$swal({
        title: `Are you sure you want to leave team ${this.team.name} ?`,
        text: 'Once you leave the team you can’t participate in tournaments until you rejoin!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Leave',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const { success } = await this.$api.team.leaveTeam(this.team.id)

            if (success) {
              this.$swal({
                icon: 'success',
                title: 'You were successfully removed from the team!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              await this.$store.dispatch('user/fetchMe')
            }
          }
        })
    },
  },
}
</script>
<style lang="scss">
.scroll-area {
  margin: auto;
}
</style>
