<template>
  <b-card no-body>
    <b-card-header
      class="d-flex justify-content-center align-items-center pt-75 pb-25"
    >
      <h5
        v-b-tooltip.hover.left="`Other team members which don't take part in any game`"
        class="mb-0"
      >
        Other members
      </h5>
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div
            v-if="!members || members.length === 0"
            class="font-italic mt-1"
          >
            No members yet
          </div>

          <vue-perfect-scrollbar
            v-else
            class="scroll-area"
            style="height: 150px;"
          >
            <div>
              <b-badge
                v-for="member in members"
                :key="member.id"
                :to="{ name: 'player-profile', params: { id: member.user.id } }"
                class="mt-1 mr-1"
                variant="light-primary"
              >
                {{ member.user.name }}
              </b-badge>
            </div>
          </vue-perfect-scrollbar>
        </b-col>
      </b-row>
      <b-row v-if="showEditButton">
        <b-col class="text-center">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mt-1"
              size="sm"
              variant="outline-primary"
              @click="handleEditMembersClick"
            >
              edit members
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    team: {
      type: Object,
      default: () => ({}),
    },
    showEditButton: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {},
  methods: {
    handleEditMembersClick() {
      this.$emit('editTeamMembersClick', this.team.id)
    },
  },
}
</script>
<style lang="scss">
.scroll-area {
  margin: auto;
}

.badge {
  min-width: 100px;
  padding: 10px;
}
</style>
