var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "social-links list-unstyled"
  }, _vm._l(_vm.links, function (link) {
    return _c('li', {
      key: link.id
    }, [_c('b-link', {
      attrs: {
        "href": link.url,
        "target": "_blank"
      }
    }, [link.link_type_id === _vm.LinkType.FACEBOOK ? _c('b-img', {
      attrs: {
        "src": require("@/assets/images/logo/facebook.png"),
        "alt": "Facebook",
        "height": "30",
        "width": "30"
      }
    }) : _vm._e(), link.link_type_id === _vm.LinkType.INSTAGRAM ? _c('b-img', {
      attrs: {
        "src": require("@/assets/images/logo/instagram.png"),
        "alt": "Instagram",
        "height": "30",
        "width": "30"
      }
    }) : _vm._e(), link.link_type_id === _vm.LinkType.DISCORD ? _c('b-img', {
      attrs: {
        "src": require("@/assets/images/logo/discord.png"),
        "alt": "Discord",
        "height": "30",
        "width": "30"
      }
    }) : _vm._e(), link.link_type_id === _vm.LinkType.TWITTER ? _c('b-img', {
      attrs: {
        "src": require("@/assets/images/logo/twitter.png"),
        "alt": "Twitter",
        "height": "30",
        "width": "30"
      }
    }) : _vm._e()], 1)], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }